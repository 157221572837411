export default [
  {
    id: 1,
    name: 'Adrianna',
    surname: 'Kowalska',
    birthDate: '1996-04-09',
    sex: 'female',
  },
  {
    id: 2,
    name: 'Bartosz',
    surname: 'Nowakowski',
    birthDate: '1988-04-09',
    sex: 'male',
  },
  {
    id: 3,
    name: 'Katarzyna',
    surname: 'Szymańska',
    birthDate: '1981-04-09',
    sex: 'female',
  },
];
